.chat-room-dialog {
    right: 84px;
    z-index: 101;
    border: 1px solid rgb(223, 225, 230);
    border-radius: 10px;
    box-shadow: rgb(9 30 66 / 50%) 0px 8px 16px -4px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 100px;
    width: 100%;
    min-width: 280px;
    max-width: 400px;
    font-family: 'Inter';
}

.chat-room {
    font-family: 'Inter';
}

.chat-room__thread-container {
    height: 100%;
    width: 100%;
}

.chat-room__reply-wrapper,
.chat-room__reply-wrapper--scroll {
    position: relative;
}

.chat-room__reply-wrapper--scroll {
    max-height: 600px;
    overflow-y: scroll;
}

.chat-room--scroll::-webkit-scrollbar,
.chat-room__thread-container::-webkit-scrollbar,
.chat-room__reply-wrapper--scroll::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.chat-room--scroll::-webkit-scrollbar-track,
.chat-room__thread-container::-webkit-scrollbar-track,
.chat-room__reply-wrapper--scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.chat-room--scroll::-webkit-scrollbar-thumb,
.chat-room__thread-container::-webkit-scrollbar-thumb,
.chat-room__reply-wrapper--scroll::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.chat-room--scroll::-webkit-scrollbar-thumb:hover,
.chat-room__thread-container::-webkit-scrollbar-thumb:hover,
.chat-room__reply-wrapper--scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.chat-room__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0 !important;
}

.chat-room__header__right,
.chat-room__header__left {
    display: flex;
    align-items: center;
}

.chat-room__header__left button,
.chat-room__header__right button {
    padding: 3px 3px;
}

.chat-room__header__left-label {
    font-size: 10px;
    padding: 0 8px;
}

.chat-room__header-splitter {
    padding: 0 8px;
    font-size: 10px;
}

.chat-room--container {
    padding: 24px 32px;
}

.chat-room__view-all span {
    font-size: 10px;
}

.comment-card {
    display: flex;
    flex-direction: column;
    border-left: 0px;
    border-top: 0px;
    animation: 0.5s linear 0s 1 normal forwards running none;
    cursor: inherit;
    outline: none;
    padding-top: 0;
    margin-top: 24px;
    position: relative;
    padding-bottom: 24px;
}

.comment-card__dialog-confirmation {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 38px;
    background-color: #4b7580;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment-card__dialog-confirmation > span {
    color: white;
    font-family: Inter;
}

.comment-card__dialog-confirmation > div {
    display: flex;
    align-items: center;
    gap: 6px;
}

.comment-card__dialog-confirmation > div > button {
    background-color: white;
    border-color: white;
    padding: 2px 4px;
}

.comment-card__dialog-confirmation span {
    font-size: 10px !important;
}

.comment-card.comment-card-reply {
    align-items: flex-end;
}

.comment-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.comment-card__header > div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.comment-card__header__profile-picture {
    font-size: 18px !important;
    height: 24px;
    width: 24px;
}

.comment-card__header__profile {
    display: flex;
    flex-direction: column;
}

.comment-card__header__profile > p {
    font-weight: 700;
    font-size: 12px;
}

.comment-card__header__profile > span {
    font-size: 11px;
}

.comment-card.comment-card-reply .comment-card__header__profile > span,
.comment-card.comment-card-reply .comment-card__header__profile > p {
    text-align: right;
}

.chat-room__header__right-action {
    color: #8993a4 !important;
}

.comment-form-action {
    margin-top: 9pt !important;
}

.comment-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
    gap: 4px;
    margin-bottom: 8px;
}

.comment-meta span {
    color: #eee;
}

.comment-meta .comment-meta__content {
    color: #4b7580;
    font-weight: 600;
    text-transform: capitalize;
}

.comment-message {
    font-size: 12px;
    background-color: #e1f1f9;
    padding: 12px 12px;
    display: block;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    margin-bottom: 0;
}

.comment-card.comment-card-reply .comment-message {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 0;
}

.comment-content {
    width: 90%;
}

.comment-footer {
    display: flex;
    align-items: center;
    gap: 12px;
}

.comment-card.comment-card-reply .comment-footer {
    justify-content: flex-end;
}

.comment-date {
    font-size: 10px !important;
}

.comment-action {
    display: flex;
    align-items: center;
    gap: 6px;
}

.comment-action button {
    padding: 0;
}

.comment-action span {
    font-size: 10px;
    color: rgb(107, 119, 140);
    cursor: pointer;
    height: 24px;
    display: flex;
    align-items: center;
    font-family: Inter;
}

.comment-action :not(:last-of-type)::after {
    /* content: '•'; */
    color: rgb(107, 119, 140);
    padding: 0px 4px;
}

.hover-comment-interaction img {
    display: none;
}

.comment__opened img,
.hover-comment-interaction:hover img {
    display: inline-block;
}

.splitter-top {
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 5px;
    width: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

.splitter-bottom {
    position: absolute;
    top: -5px;
    left: 0;
    height: 5px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

.comment__item {
    z-index: 5;
}

.comment__item__action-hover-container {
    display: none;
    height: 50px;
}

.comment__item__action-comment {
    color: white;
    background-color: #013c4d;
    outline: none;
    box-shadow: -1px 5px 5px 1px rgb(0 0 0 / 10%);
    font-family: Inter;
    font-size: 15px;
    padding: 12px 16px;
    border-radius: 24px;
    margin-left: auto;
    margin-right: auto;
    left: 15px;
    font-weight: 500;
    max-width: 140px;
    border: none;
}

.comment__item__action-comment:hover {
    background-color: #002E3C;
}

.comment__item__action-comment svg {
    margin-right: 4px;
}

.comment__item:has(.comment__item__action-hover-container):hover > .comment__item__action-hover-container {
    display: block;
    position: absolute;
    top: -40px;
}

.comment__item:has(.comment__item__action-hover-container):hover,
.comment__item:has(.comment__item__action-hover-container).comment__opened {
    background-color: #e1f1f9;
}

.comment__item.comment__has-thread:has(.comment-item__icon-highlight) {
    background-color: #ffe69b !important;
    cursor: pointer;
}

.comment__item > span,
.comment__item > img {
    background-color: white;
}
